<template>
  <div class="live_cam">
    
    <div id="dplayer" style="width:100%;height:215px;margin-bottom:30px;"></div>

    <loadings :loading='searchLoading' />
    <copyright v-show='!searchLoading' />
  </div>
</template>
<script>
import DPlayer from 'dplayer';
import Loadings from '@/components/loading'
import { Field, Uploader, Picker, Rate } from 'vant'
import PageMixin from '@/mixins/page'
import Copyright from '@/components/Copyright'
import Hls from 'hls.js'
import { isEmpty } from '@/common/validate'
import upload from '@/utils/upload'

export default {
  name: 'ScheduleLive',
  components: {
    [Field.name]: Field,
    [Uploader.name]: Uploader,
    [Picker.name]: Picker,
    [Rate.name]: Rate,
    loadings:Loadings,
    Copyright,
  },
  filters: {
    formatEnd(value) {
      return value && value.slice(-8)
    }
  },
  mixins: [PageMixin],
  data() {
    return {
      searchLoading:false,
      loading: false,
      player: null,
      hls:null,
      playerOtions: {
        autoplay:false,
        live: true,
        volume:0,
        muted: true,
        playbackSpeed:false,
        preload: 'none',
        contextmenu:[],
        video: {
            url:'',
            type: 'customHls',
            customType: {
                customHls: function (video, player) {
                    this.hls = new Hls();
                    this.hls.loadSource(video.src);
                    this.hls.attachMedia(video);
                    player.events.on('destroy', () => {
                        hls.stopLoad();
                        hls.destroy();
                    });
                },
            },
          }
      }
    }
  },
  created() {
    this.getLive();
  },
  mounted() {
    document.addEventListener('WeixinJSBridgeReady', function onBridgeReady() {
        WeixinJSBridge.invoke('hideOptionMenu')
    });
  },
  beforeDestroy() {
    if(this.player) {
      this.player.destroy()
    }
  },
  methods: {
      getLive() {
        this.searchLoading = true
        let book_id = this.$route.query.book_id
        this.$api.schedule_live({ book_id:book_id }).then(res => {
          if(res.data.live){
            this.playerOtions.video.url = res.data.live;
            this.$nextTick(() => {
              this.playerOtions.container = document.getElementById('dplayer');
              this.player = new DPlayer(this.playerOtions);
            })
            this.searchLoading = false
          }
        })
      }
  }
}
</script>
<style lang="less" scoped>
    // .live_cam{
    //   position: absolute;
    //   width: 100%;
    //   height: 100%;
    // }
  .card{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 15px;
    &-media{
      width: 130px;
      border-radius: 4px;
      overflow: hidden;
      margin-right: 10px;
    }
    &-inner{
      flex: 1;
      height: 60px;
    }
    &-title{
      max-width: 140px;
      font-size: 14px;
      font-weight: bold;
      color: #333;
      margin-bottom: 10px;
    }
    &-txt{
      font-size: 12px;
      color: #666;
    }
  }
  .teacher{
    &-inner{
      padding: 10px 15px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 60px;
    }
    &-avatar{
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 10px;
    }
    &-title{
      font-size: 14px;
      color: #333;
    }
    &-hd{
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    &-bd{
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 12px;
      color: #666;
    }
  }

  .image-tips{
    padding: 10px 16px;
    font-size: 14px;
    color: #666;
    background: #fff;
  }
</style>

